
import Enrollment from '@/types/enrollment'
import utils from '@/utils/utils'
import { defineComponent } from 'vue'

export default defineComponent({
  data () {
    return {
      enrollments: [] as Enrollment[]
    }
  },
  async mounted () {
    const response = await this.$api.getAccountEnrollments()
    if (response.enrollments) {
      this.enrollments = response.enrollments
    }
  },
  methods: {
    dateSummary (dateStrings: string[]) {
      return utils.getDateSummary(dateStrings)
    },
    timeSummary (startTime: string, stopTime: string) {
      return utils.getTimeSummary(startTime, stopTime)
    }
  }
})
