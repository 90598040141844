<template>
  <div class="container mt-3">
    <h4 class="mb-3">Enrollments</h4>
    <ul class="list-group">
      <li class="list-group-item" v-for="enrollment in enrollments" :key="enrollment.id" :class="enrollment.status">
        <strong>{{ enrollment.studentName }}</strong> in <strong>{{ enrollment.className }}</strong>
        <div class="text-muted">{{ dateSummary(enrollment.classDates) }}; {{ timeSummary(enrollment.classStartTime, enrollment.classStopTime) }}</div>
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import Enrollment from '@/types/enrollment'
import utils from '@/utils/utils'
import { defineComponent } from 'vue'

export default defineComponent({
  data () {
    return {
      enrollments: [] as Enrollment[]
    }
  },
  async mounted () {
    const response = await this.$api.getAccountEnrollments()
    if (response.enrollments) {
      this.enrollments = response.enrollments
    }
  },
  methods: {
    dateSummary (dateStrings: string[]) {
      return utils.getDateSummary(dateStrings)
    },
    timeSummary (startTime: string, stopTime: string) {
      return utils.getTimeSummary(startTime, stopTime)
    }
  }
})
</script>
